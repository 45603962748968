<template>
    <div class="spread">
        <div class="visual">
            <div class="visual-minimize" @click="handleFullScreen()">
                <img :src="require('@/assets/img/iccc_fullscreen.png')"/>
            </div>
            <div class="visual-move" :style="{ 'margin-left': getVisualPosition, width: getVisualWidth }">
                <div
                    :class="['item', { current: index === activeIndex }, { disabled: isDisabled(item) }]"
                    v-for="(item, index) in cards"
                    :key="'item' + index"
                    :style="{
                        'z-index': getZIndex(index)[0],
                        'transform': `scale(${getZIndex(index)[1]})`,
                        opacity: getZIndex(index)[2] === 'hide' ? 0 : 1,
                        'pointer-events': getZIndex(index)[2] === 'hide' ? 'none' : 'auto',
                        'margin-left': getZIndex(index)[3] + 'px',
                    }"
                    @click="handleChangeCurrent(index)"
                >
                    <div class="card">
                        <div class="grayed" v-if="getZIndex(index)[1] != 1"></div>
                        <img :src="require(`@/assets/img/cards/${item.id}-${language}.png`)" />
                    </div>
                </div>
            </div>
            <div class="visual-option">
                <div class="prev" @click="handlePrev()"><span></span></div>
                <div class="next" @click="handleNext()"><span></span></div>
            </div>
        </div>
        <div class="pickers">
            <div class="pickers-move" :style="{ 'margin-left': getPickerPosition, width: getPickerWidth }">
                <div
                    :class="[
                        'item',
                        {
                            active: isSelected(item),
                            current: index === activeIndex,
                        },
                        
                        { disabled: isDisabled(item) }
                    ]"
                    v-for="(item, index) in cards"
                    :key="'item' + index"
                >
                    <div class="card" @click="handleChangeCurrent(index)">
                        <div class="checked"></div>
                        <img :src="require(`@/assets/img/cards/${item.id}-${language}.png`)" />
                    </div>

                    <div class="option">
                        <button
                            :class="isSelected(item) ? 'remove' : 'select'"
                            @click="handleRemove(item)"
                        >{{ isSelected(item) ? $t('Remove') : $t('Select') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    props: {
        cards: {
            type: Array,
            default () {
                return []
            },
        },

        selected: {
            type: Array,
            default () {
                return []
            },
        }
    },

    data (){
        return {
            activeIndex: 5,

            cardStyle: {
                width: 100,
                height: 200,
                marginSides: 10,
            },

            visualStyle: {
                width: 70,
                height: 429,
            },

            isFullScreen: false,
            windowWidth: window.innerWidth
        }
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },

    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize);
    },

    watch: {
        windowWidth(newWidth, oldWidth) {
            console.log(`it changed to ${newWidth} from ${oldWidth}`)
        }
    },

    computed: {
        ...mapFields(['completed', 'language']),

        getVisualWidth () {
            const { width } = this.visualStyle;
            return this.cards.length *  width + 'px';
        },

        getPickerWidth () {
            const { width, marginSides } = this.cardStyle;
            return this.cards.length *  (width + marginSides) + 'px';
        },

        getVisualPosition () {
            let widthMultiplier = this.windowWidth > 1024 ? 6 : 3
            const { width } = this.visualStyle;
            const fromCenter = (widthMultiplier * width) * -1;
            const fromIndex = (this.activeIndex * width) * -1;
            return fromIndex - fromCenter + 'px';
        },

        getPickerPosition () {
            const { width, marginSides } = this.cardStyle;
            const fromCenter = (5 * (width + marginSides)) * -1;
            const fromIndex = (this.activeIndex * (width + marginSides)) * -1;
            return fromIndex - fromCenter + 'px';
        }
    },

    methods: {
        onResize() {
            this.windowWidth = window.innerWidth
        },

        handleRemove(item){
            this.$emit('removeItem', item);
        },

        isSelected(val) {
            return this.selected.find(item => item.id === val.id);
        },

        isDisabled (val) {
            return this.completed.find(item => item.id === val.id)
        },

        handleChangeCurrent(index){
            this.activeIndex = index;
        },

        getZIndex(index){
            if(index < this.activeIndex - 2){
                return [0, 0.8, 'hide', 0];
            } else if(index < this.activeIndex - 1){
                return [0, 0.8, 'show', 0];
            } else if(index === this.activeIndex - 1){
                return [1, 0.9, 'show', 0];
            } else if(index > this.activeIndex + 2){
                return [0, 0.8, 'hide', 0];
            } else if(index > this.activeIndex + 1){
                return [0, 0.8, 'show', 20];
            } else if(index === this.activeIndex + 1){
                return [1, 0.9, 'show', 20];
            } else {
                return [2, 1,'show', 0];
            }
        },

        handlePrev(){
            if (this.activeIndex < this.cards.length){
                this.activeIndex = this.activeIndex + 1;
            }
        },

        handleNext(){
            if (this.activeIndex > 0){
                this.activeIndex = this.activeIndex - 1;
            }
        },

        handleFullScreen(){
            const elem = document.documentElement;
            if (!this.isFullScreen) {
                this.isFullScreen = true;
                elem.requestFullscreen();
            } else {
                this.isFullScreen = false;
                document.exitFullscreen();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.spread {
    width: 810px;
    margin: auto;
    padding-bottom: 110px;

    @include desktop {
        width: 1100px;
    }

    .visual {
        position: relative;
        height: 505px;
        width: 100%;
        background: #F3F3F3 0% 0% no-repeat padding-box;
        overflow: hidden;

        .visual-minimize {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 46px;
            height: 42px;
            cursor: pointer;
            z-index: 999;

            img {
                width: 100%;
            }
        }

        .visual-move {
            position: relative;
            height: 460px;
            padding-top: 15px;
            box-sizing: border-box;
            display: flex;

        }

        .item {
            position: relative;
            width: 70px;
            height: 429px;
            transition: transform .2s ease-in-out;
            cursor: pointer;

            .card {
                position: relative;
                width: 390px;
                height: 475px;

                img {
                    width: 100%;
                    height: 100%;
                }

                .grayed {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #0000002b;
                }
            }

            &.disabled {
                opacity: 0.4;
                pointer-events: none;
            }
            
        }

        .visual-option {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 0;

            .next {
                position: absolute;
                width: 40px;
                height: 50px;
                left: 10px;
                bottom: -254px;
                box-shadow: 0px 3px 6px #00000047;
                background-color: #fff;
                cursor: pointer;

                span {
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin-top: 13px;
                    margin-left: 15px;
                    border: 1.5px solid #000;
                    border-left: unset;
                    border-bottom: unset;
                    transform: rotate(-136deg);
                }
            }

            .prev {
                position: absolute;
                width: 40px;
                height: 50px;
                right: 10px;
                bottom: -254px;
                box-shadow: 0px 3px 6px #00000047;
                cursor: pointer;
                background-color: #fff;
                span {
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin-top: 13px;
                    border: 1.5px solid #000;
                    border-left: unset;
                    border-bottom: unset;
                    transform: rotate(45deg);
                }
            }
        }
    }

    .pickers {
        width: 100%;
        height: 200px;
        box-sizing: border-box;
        overflow: hidden;

        .pickers-move {
            height: 200px;
            padding-top: 31px;
            display: flex;
            transition: margin 700ms;
        }

        .item {
            width: 100px;
            height: 200px;
            margin: 0 5px;

            &.active {
                .card {
                    outline: 3px solid #A7AAFF;

                    .checked {
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 20px;
                        height: 20px;
                        background-color: #A7AAFF;

                        &:after {
                            content: "";
                            width: 13px;
                            height: 8px;
                            border: 2px solid #fff;
                            border-top: none;
                            border-right: none;
                            position: absolute;
                            left: 5px;
                            top: 2px;
                            transform: rotate(-46deg);
                        }
                    }
                }
            }

            &.current {
                margin-top: -20px;

                .card {
                    outline: 3px solid #A7AAFF;
                }
            }

            .card {
                width: 100px;
                height: 130px;
                margin-bottom: 8px;
                cursor: pointer;
                position: relative;
                border-radius: 4px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .option {
                text-align: center;

                button {
                    border-radius: 3px;
                    padding: 5px 10px;
                    font-size: 12px;
                    font-weight: 600;

                    &.remove {
                        border: 1px solid #FF6868;
                        color: #FF2929;
                        background-color: transparent;
                    }

                    &.select {
                        border: 1px solid #00AEEF;
                        color: #fff;
                        background-color: #00AEEF;
                    }
                }
            }

            &.disabled {
                opacity: 0.4;
                pointer-events: none;
            }
        }
    }
}
</style>