var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spread"},[_c('div',{staticClass:"visual"},[_c('div',{staticClass:"visual-minimize",on:{"click":function($event){return _vm.handleFullScreen()}}},[_c('img',{attrs:{"src":require('@/assets/img/iccc_fullscreen.png')}})]),_c('div',{staticClass:"visual-move",style:({ 'margin-left': _vm.getVisualPosition, width: _vm.getVisualWidth })},_vm._l((_vm.cards),function(item,index){return _c('div',{key:'item' + index,class:['item', { current: index === _vm.activeIndex }, { disabled: _vm.isDisabled(item) }],style:({
                    'z-index': _vm.getZIndex(index)[0],
                    'transform': ("scale(" + (_vm.getZIndex(index)[1]) + ")"),
                    opacity: _vm.getZIndex(index)[2] === 'hide' ? 0 : 1,
                    'pointer-events': _vm.getZIndex(index)[2] === 'hide' ? 'none' : 'auto',
                    'margin-left': _vm.getZIndex(index)[3] + 'px',
                }),on:{"click":function($event){return _vm.handleChangeCurrent(index)}}},[_c('div',{staticClass:"card"},[(_vm.getZIndex(index)[1] != 1)?_c('div',{staticClass:"grayed"}):_vm._e(),_c('img',{attrs:{"src":require(("@/assets/img/cards/" + (item.id) + "-" + _vm.language + ".png"))}})])])}),0),_c('div',{staticClass:"visual-option"},[_c('div',{staticClass:"prev",on:{"click":function($event){return _vm.handlePrev()}}},[_c('span')]),_c('div',{staticClass:"next",on:{"click":function($event){return _vm.handleNext()}}},[_c('span')])])]),_c('div',{staticClass:"pickers"},[_c('div',{staticClass:"pickers-move",style:({ 'margin-left': _vm.getPickerPosition, width: _vm.getPickerWidth })},_vm._l((_vm.cards),function(item,index){return _c('div',{key:'item' + index,class:[
                    'item',
                    {
                        active: _vm.isSelected(item),
                        current: index === _vm.activeIndex,
                    },
                    
                    { disabled: _vm.isDisabled(item) }
                ]},[_c('div',{staticClass:"card",on:{"click":function($event){return _vm.handleChangeCurrent(index)}}},[_c('div',{staticClass:"checked"}),_c('img',{attrs:{"src":require(("@/assets/img/cards/" + (item.id) + "-" + _vm.language + ".png"))}})]),_c('div',{staticClass:"option"},[_c('button',{class:_vm.isSelected(item) ? 'remove' : 'select',on:{"click":function($event){return _vm.handleRemove(item)}}},[_vm._v(_vm._s(_vm.isSelected(item) ? _vm.$t('Remove') : _vm.$t('Select')))])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }